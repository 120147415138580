application.register("part_form_search", class extends Stimulus.Controller {
    get target() {
        return {
            additional: this.targets.find("additional"),
            id: this.targets.find("id"),
            disposition: this.targets.find("disposition"),
            approval: this.targets.find("approval"),
            otherSpecifics: this.targets.find("otherSpecifics"),
        }
    }

    initialize() {
        this.toggleFilterVisibility();
    }

    togglePropertyType() {
        this.toggleFilterVisibility();
    }

    toggleAdditional() {
        if (this.target.additional.offsetHeight === 0) {
            this.element.classList.add("is--active-additional");
            this.expand(this.target.additional);
            this.collapse(this.target.id);
            this.element.classList.remove("is--active-id");
        } else {
            this.element.classList.remove("is--active-additional");
            this.collapse(this.target.additional);
        }

        if (typeof locomotive !== "undefined") {
            setTimeout(() => {
                locomotive.update();
            }, 350);
        }
    }

    toggleId() {
        if (this.target.id.offsetHeight === 0) {
            this.element.classList.add("is--active-id");
            this.expand(this.target.id);
            this.collapse(this.target.additional);
            this.element.classList.remove("is--active-additional");
        } else {
            this.element.classList.remove("is--active-id");
            this.collapse(this.target.id);
        }

        if (typeof locomotive !== "undefined") {
            setTimeout(() => {
                locomotive.update();
            }, 350);
        }
    }

    toggleFilterVisibility() {
        let type = parseInt(this.element.querySelector('[name="propertyType"]').value);

        if (!type || type === 1) { // byt nebo vse
            if(typeof this.target.disposition !== "undefined") {
                this.target.disposition.style.display = 'block';
            }
            if(typeof this.target.approval !== "undefined") {
                this.target.approval.style.display = 'block';
            }
            if(typeof this.target.otherSpecifics !== "undefined") {
                this.target.otherSpecifics.style.display = 'block';
            }
        } else if(type === 2) { // komerce
            if(typeof this.target.disposition !== "undefined") {
                this.target.disposition.style.display = 'none';
            }
            if(typeof this.target.approval !== "undefined") {
                this.target.approval.style.display = 'block';
            }
            if(typeof this.target.otherSpecifics !== "undefined") {
                this.target.otherSpecifics.style.display = 'none';
            }
        } else if(type === 3) { // dum
            if(typeof this.target.disposition !== "undefined") {
                this.target.disposition.style.display = 'none';
            }
            if(typeof this.target.approval !== "undefined") {
                this.target.approval.style.display = 'block';
            }
            if(typeof this.target.otherSpecifics !== "undefined") {
                this.target.otherSpecifics.style.display = 'block';
            }
        } else if(type === 4) { // pozemek
            if(typeof this.target.disposition !== "undefined") {
                this.target.disposition.style.display = 'none';
            }
            if(typeof this.target.approval !== "undefined") {
                this.target.approval.style.display = 'none';
            }
            if(typeof this.target.otherSpecifics !== "undefined") {
                this.target.otherSpecifics.style.display = 'none';
            }
        }
    }

    reset(event) {
        event.preventDefault();

        let url = event.currentTarget.getAttribute('data-action-url');

        this.element.querySelectorAll('[name="approval"],[name="idNumber"],[type="text"][class="datepicker-input"]').forEach((elm) => {
            elm.value = '';
        });

        this.element.querySelectorAll('select:not([multiple])').forEach((elm) => {
            elm.selectedIndex = 0;
            elm.dispatchEvent(new Event('change', {'bubbles': true, 'cancelable': true}));
        });

        this.element.querySelectorAll('select[multiple]').forEach((elm) => {
            elm.selectedIndex = -1;
            elm.dispatchEvent(new Event('change', {'bubbles': true, 'cancelable': true}));
        });

        this.element.querySelectorAll('.part_ui_range').forEach((elm) => {
            let defaultMin = elm.querySelector('[data-min]').dataset.min;
            let defaultMax = elm.querySelector('[data-max]').dataset.max;

            if (defaultMin !== null) {
                elm.querySelector('[data-range-input="min"]').value = defaultMin;
                elm.querySelector('[data-range-input="min"]').dispatchEvent(new Event('change', {'bubbles': true, 'cancelable': true}));
            }

            if (defaultMax !== null) {
                elm.querySelector('[data-range-input="max"]').value = defaultMax;
                elm.querySelector('[data-range-input="max"]').dispatchEvent(new Event('change', {'bubbles': true, 'cancelable': true}));
            }
        });

        /*this.element.dispatchEvent(new Event('submit', {'bubbles': true, 'cancelable': true}));*/
        fetch(url, {method: 'POST', headers: {'X-Requested-With': 'XMLHttpRequest'}}).then((response) => {
            return response.json();
        }).then((payload) => {
            fetchHandler(payload);
        });
    }

    expand(element) {
        element.style.height = element.scrollHeight + "px";
        element.classList.add("state--active");
    }

    collapse(element) {
        element.style.height = "0px";
        element.classList.remove("state--active");
    }
});