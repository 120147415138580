const cdnjs = {
    "picturefill": "https://cdn.jsdelivr.net/npm/picturefill@3.0.3/dist/picturefill.min.js",
    "recaptcha": "https://www.google.com/recaptcha/api.js?render={apikey}",
    "seamless": "https://cdn.jsdelivr.net/npm/seamless-scroll-polyfill@1.2.3/dist/es5/seamless.min.js",
    "pickr": "https://cdn.jsdelivr.net/npm/@simonwep/pickr@1.8.0/dist/pickr.min.js",
    "pickr_css": "https://cdn.jsdelivr.net/npm/@simonwep/pickr@1.8.0/dist/themes/nano.min.css",
    "cleave": "https://cdn.jsdelivr.net/npm/cleave.js@1.6.0/dist/cleave.min.js",
    "datepicker": "https://cdn.jsdelivr.net/npm/vanillajs-datepicker@1.0.3/dist/js/datepicker-full.min.js",
    "datepicker_i18n": "https://cdn.jsdelivr.net/npm/vanillajs-datepicker@1.0.3/dist/js/locales/cs.js",
    "chart": "https://cdn.jsdelivr.net/npm/chart.js@2.9.4/dist/Chart.min.js",
    "slimselect": "https://cdn.jsdelivr.net/npm/slim-select@1.26.0/dist/slimselect.min.js",
    "noui": "https://cdnjs.cloudflare.com/ajax/libs/noUiSlider/10.1.0/nouislider.min.js",
    "popper": "https://cdn.jsdelivr.net/npm/@popperjs/core@2.6.0/dist/umd/popper.min.js",
    "tippy": "https://cdn.jsdelivr.net/npm/tippy.js@6.2.7/dist/tippy.umd.js",
    "tippy_css": "https://cdn.jsdelivr.net/combine/npm/tippy.js@6.2.7/animations/shift-away.min.css,npm/tippy.js@6.2.7/dist/tippy.min.css",
    "shareApiPolyfill": "https://cdn.jsdelivr.net/npm/share-api-polyfill@1.0.18/dist/share-min.js",
    "googlemaps": "https://maps.googleapis.com/maps/api/js?key={apikey}",
    "googlemaps_cluster": "https://cdn.jsdelivr.net/npm/@google/markerclustererplus@5.1.0/dist/markerclustererplus.min.js",
    "jquery": "https://cdn.jsdelivr.net/npm/jquery@3.5.1/dist/jquery.min.js",
    "fancybox": "https://cdn.jsdelivr.net/npm/@fancyapps/fancybox@3.5.7/dist/jquery.fancybox.min.js",
    "fancybox_css": "https://cdn.jsdelivr.net/npm/@fancyapps/fancybox@3.5.7/dist/jquery.fancybox.min.css",
    "autosize": "https://cdn.jsdelivr.net/npm/autosize@4.0.2/dist/autosize.min.js",
    "printjs": "https://cdn.jsdelivr.net/npm/print.js@1.0.18/dist/print.min.js",
};