application.register("comp_highlights", class extends Stimulus.Controller {
    setActive() {
        if(window.innerWidth >= 960 && document.documentElement.classList.contains("no-touch")) {
            this.element.classList.add("state--active");
        }
    }

    unsetActive() {
        this.element.classList.remove("state--active");
    }
});