application.register("part_graph", class extends Stimulus.Controller {
    get target() {
        return {
            graph: this.targets.find("graph")
        }
    }

    connect() {
        const self = this;

        inView(self.element, ()=> {
            importScript(cdnjs.chart, () => {
                let data = JSON.parse(self.data.get("values"));
                let datasets = [];

                data.values.forEach((value, index) => {
                    datasets.push({
                        backgroundColor: getComputedStyle(self.element).color,
                        data: data.values[index],
                    })
                });

                new Chart(self.target.graph, {
                    type: 'bar',
                    data: {
                        labels: data.labels,
                        datasets: datasets
                    },
                    options: {
                        responsive: true,
                        aspectRatio: 3,
                        title: {
                            display: false
                        },
                        hover: {
                            mode: 'nearest',
                            intersect: true
                        },
                        onHover: function(e, activePoints) {
                            let tooltip = document.querySelector(".elm_part_tooltip");

                            if (activePoints.length > 0 && typeof data.data !== "undefined") {
                                let tooltipData = data.data[activePoints[0]._datasetIndex][activePoints[0]._index];

                                if (typeof tooltipData === "undefined") {
                                    return false;
                                }

                                tooltip.innerHTML = "";
                                tooltipData.forEach((item) => {
                                    tooltip.insertAdjacentHTML("beforeend", `
                                    <div class="col">
                                        <div class="elm_title"><strong>${item.value}</strong></div>
                                    </div>
                                `);
                                });

                                tooltip.style.left = (e.clientX) + 'px';
                                tooltip.style.top = (((typeof locomotive.scroll.instance.delta !== "undefined") ? locomotive.scroll.instance.delta.y : 0) + e.clientY - (document.documentElement.clientWidth < 768 ? 100 : 100)) + "px";
                                tooltip.style.display = "flex";

                                window.addEventListener('scroll',self.hideTooltip);

                            } else {
                                tooltip.style.display = "none";
                            }
                        },
                        tooltips: {
                            enabled: false
                        },
                        legend: {
                            display: false
                        },
                        scales: {
                            xAxes: [{
                                display: true,
                                gridLines: {
                                    display: false,
                                },
                                ticks: {
                                    fontSize: 10,
                                    fontFamily: 'Montserrat, serif',
                                    fontColor: getComputedStyle(document.body).color.replace("rgb","rgba").replace(")",", 0.6)"),
                                    fontStyle: 500,
                                    padding: 0,
                                    opacity: 0.6
                                }
                            }],
                            yAxes: [{
                                display: false
                            }]
                        }
                    }
                });
            });
        })
    }

    hideTooltip() {
        let self = this;
        let tooltip = document.querySelector(".elm_part_tooltip");
        tooltip.style.display = "none";

        window.removeEventListener('scroll',self.hideTooltip);
    }
});