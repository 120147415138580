application.register("part_item_person", class extends Stimulus.Controller {
    setActive() {
        if(window.innerWidth >= 960) {
            this.element.classList.add("state--active");
        }
    }

    unsetActive() {
        if(window.innerWidth >= 960) {
            this.element.classList.remove("state--active");
        }
    }

    handleClick() {
        if(window.innerWidth < 960) {
            if(this.element.classList.contains("state--active")) {
                this.element.classList.remove("state--active");
            }
            else {
                this.element.classList.add("state--active");
            }
        }
    }
});