application.register("part_ui_select", class extends Stimulus.Controller {
    connect() {
        let select = this.element.querySelector("select");

        if(select.getAttribute("multiple") !== null) {
            this.element.setAttribute("data-placeholder",select.dataset.placeholder);
            let attribute = select.getAttribute("data-action");
            let action = "change->part_ui_select#handleChange";

            if (attribute === null) {
                select.setAttribute("data-action", action);
            } else if (attribute.indexOf(action) === -1) {
                select.setAttribute("data-action", `${attribute} ${action}`);
            }

            if(select.value === "") {
                this.element.classList.add("state--placeholder");
            }
        }

        bodyLoaded(() => {
            if (document.documentElement.classList.contains("mobile")) {
                return;
            }

            importScript(cdnjs.slimselect, () => {
                let slimselect = new SlimSelect({
                    select: select,
                    showSearch: false,
                    allowDeselectOption: true,
                    addToBody: true,
                    placeholder: select.dataset["placeholder"],
                });

                bodyLoaded(()=>{
                    setTimeout(()=>{
                        locomotive.on("scroll", () => {
                            slimselect.close();
                        });
                    },1000);
                });
            });
        });
    }

    handleChange(e) {
        if(e.currentTarget.value === "") {
            this.element.classList.add("state--placeholder");
        }
        else {
            this.element.classList.remove("state--placeholder");
        }
    }
});