application.register("part_detail_objects_table", class extends Stimulus.Controller {

    openLink(e) {
        if(e.target.closest("a") !== null) {
            return;
        }

        swup.loadPage({
            url: e.currentTarget.dataset.actionUrl
        });
    }
});