const LibAnchor = {
    animation: (element, offset) => {
        if(typeof offset === "undefined") {
            offset = 0;
        }
        let attr = "data-offset";

        if (window.innerWidth > 960) {
            if (element.getAttribute(attr) !== null) {
                if (isNaN(parseInt(element.getAttribute(attr)))) {
                    offset = document.querySelector(element.getAttribute(attr)).offsetHeight;
                } else {
                    offset = parseInt(element.getAttribute(attr));
                }
            }
        }
        else {
            offset = document.querySelector("#layout_header").offsetHeight;
        }

        if (typeof locomotive !== "undefined") {
            locomotive.scrollTo(element, -offset);
        }
        else {
            document.documentElement.scroll({top: element.offsetTop - offset, behavior: "smooth"});
        }
    },
    action: (element) => {
        let href = element.getAttribute("href");

        if (href === null) {
            href = element.getAttribute("data-action-href");
        }

        let id = document.querySelector("[id=" + href.replace('#', '') + "]");
        let options = element.getAttribute("data-action-options");

        if (options === null) {
            options = "";
        }

        if (id !== null) {
            if (!(options.indexOf("mobile") > -1 && window.innerWidth > 960)) {

                LibAnchor.animation(id);

                if (options.indexOf("hash") > -1) {
                    window.location.hash = id;
                }
            }
        }
    },
    init: () => {
        let selector = [...document.querySelectorAll("[id]")];

        if (selector[0] !== null) {

            selector.forEach((element) => {
                if (window.location.hash && element.getAttribute("id") === window.location.hash.replace('#', '')) {
                    setTimeout(() => {
                        window.scrollTo(0,0);
                    }, 1);
                    cssLoaded(function () {
                        LibAnchor.animation(element);
                    }, 50);
                }
            });
        }
    }
};

LibAnchor.init();