application.register("part_item_project_expand", class extends Stimulus.Controller {
    get target() {
        return {
            available: this.targets.find("available")
        }
    }

    toggleAvailable(e) {
        e.stopPropagation();
        e.preventDefault();

        if (this.target.available.offsetHeight === 0) {
            this.target.available.style.height = this.target.available.scrollHeight + "px";
            this.element.classList.add("state--active");
        } else {
            this.target.available.style.height = "0px";
            this.element.classList.remove("state--active");
        }

        if (typeof locomotive !== "undefined") {
            setTimeout(() => {
                locomotive.update();
            }, 350);
        }
    }

    share(e) {
        e.stopPropagation();
        e.preventDefault();

        let options = JSON.parse(e.currentTarget.dataset.actionOptions);

        importScript(cdnjs.shareApiPolyfill,()=>{
            let language = lang;

            if(["cs","de", "da", "en", "es", "fr", "ja", "ko", "nl", "pt", "ru", "sk", "tr", "zh"].indexOf(lang) === -1) {
                language = "en";
            }

            navigator.share({
                    title: options.title,
                    text: options.description,
                    url: options.url,
                    // fbId: '123456789123456',
                },
                {
                    copy: true,
                    email: true,
                    print: false,
                    sms: false,
                    messenger: true,
                    facebook: true,
                    whatsapp: true,
                    twitter: true,
                    linkedin: true,
                    telegram: false,
                    skype: false,
                    language: language
                }
            )
                .then()
                .catch( error => console.log(error));
        });
    }
});