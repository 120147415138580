const LibNoUI = function (element, options) {
    let fn = {
        this: element,
        slider: element.querySelector("[data-range-slider]"),
        elm_min: options.elm_min,
        elm_min_hidden: options.elm_min_hidden,
        elm_max: options.elm_max,
        elm_max_hidden: options.elm_max_hidden,
        unit: options.unit,
        isCurrency: element.classList.contains("is--currency")
    };

    let lang = document.documentElement.lang;

    if (fn.slider !== null) {
        let min_val = 0;
        let max_val = 0;
        if (typeof fn.elm_min_hidden !== "undefined") {
            min_val = fn.elm_min_hidden.value;
        }
        if (typeof fn.elm_max_hidden !== "undefined") {
            max_val = fn.elm_max_hidden.value;
        }

        let slider_min = parseFloat(fn.slider.dataset.min),
            slider_max = parseFloat(fn.slider.dataset.max),
            slider_step = parseFloat(fn.slider.dataset.step);

        if (fn.this.classList.contains("type--single")) {
            noUiSlider.create(fn.slider, {
                start: max_val,
                step: slider_step,
                connect: [true, false],
                range: {
                    'min': slider_min,
                    'max': slider_max
                }
            });

            fn.elm_max.addEventListener('change', function () {
                if (fn.elm_max.value !== "") {
                    fn.slider.noUiSlider.set([fn.elm_min_hidden.value, fn.elm_max_hidden.value]);
                }
            });
        } else {
            noUiSlider.create(fn.slider, {
                start: [min_val, max_val],
                step: slider_step,
                connect: true,
                range: {
                    'min': slider_min,
                    'max': slider_max
                }
            });

            fn.elm_max.addEventListener('change', function () {
                if (fn.elm_max.value !== "") {
                    fn.slider.noUiSlider.set([parseFloat((fn.elm_min.value).replace(/\s/g, '')), parseFloat((fn.elm_max.value).replace(/\s/g, ''))]);
                }
            });

            fn.elm_min.addEventListener('change', function () {
                if (fn.elm_min.value !== "") {
                    fn.slider.noUiSlider.set([parseFloat((fn.elm_min.value).replace(/\s/g, '')), parseFloat((fn.elm_max.value).replace(/\s/g, ''))]);
                }
            });
        }

        fn.slider.noUiSlider.on('update', function (values, handle) {
            let value = parseFloat(values[handle]);

            if (!handle) {
                if (typeof fn.elm_min_hidden !== "undefined") {
                    fn.elm_min_hidden.value = value;
                }

                if (typeof fn.elm_min !== "undefined") {
                    if (fn.isCurrency) {
                        fn.elm_min.value = value.toLocaleString(lang, {
                            style: 'currency',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                            currency: fn.unit
                        })
                    }
                    else {
                        fn.elm_min.value = value.toLocaleString(lang, {
                            style: 'decimal',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0
                        }) + ` ${fn.unit}`
                    }
                }
            }
            if (handle || typeof fn.elm_min === "undefined") {
                if (typeof fn.elm_max_hidden !== "undefined") {
                    fn.elm_max_hidden.value = value;
                }

                if (typeof fn.elm_max !== "undefined") {
                    if (fn.isCurrency) {
                        fn.elm_max.value = value.toLocaleString(lang, {
                            style: 'currency',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                            currency: fn.unit
                        })
                    }
                    else {
                        fn.elm_max.value = value.toLocaleString(lang, {
                            style: 'decimal',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0
                        }) + ` ${fn.unit}`
                    }
                }
            }
        });


        fn.slider.noUiSlider.on('change', function (values, handle) {
            if (!handle) {
                if (typeof fn.elm_min !== "undefined") {
                    if (fn.elm_min.value !== "") {
                        fn.elm_min.dispatchEvent(new Event('change', {'bubbles': true}));
                    }
                }
                if (typeof fn.elm_min_hidden !== "undefined") {
                    fn.elm_min_hidden.dispatchEvent(new Event('change', {'bubbles': true}));
                }
            }
            if (handle || typeof fn.elm_min === "undefined") {
                if (typeof fn.elm_max !== "undefined") {
                    if (fn.elm_max.value !== "") {
                        fn.elm_max.dispatchEvent(new Event('change', {'bubbles': true}));
                    }
                }
                if (typeof fn.elm_max_hidden !== "undefined") {
                    fn.elm_max_hidden.dispatchEvent(new Event('change', {'bubbles': true}));
                }
            }
        });
    }
};