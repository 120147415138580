application.register("part_copy", class extends Stimulus.Controller {
    get target() {
        return {
            link: this.targets.find("link"),
            notice: this.targets.find("notice")
        }
    }

    connect() {
        this._noticeTimeout = "";
    }

    copy() {
        let textArea = document.createElement('textArea');
        textArea.value = this.element.dataset['actionValue'];
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("copy")
        document.body.removeChild(textArea);

        clearTimeout(this._noticeTimeout);
        this.target.notice.classList.add("state--active");
        this._noticeTimeout = setTimeout(()=> {
            this.target.notice.classList.remove("state--active");
        },3500);
    }
});