application.register("comp_detail_data", class extends Stimulus.Controller {
    share(e) {
        e.stopPropagation();
        e.preventDefault();

        importScript(cdnjs.shareApiPolyfill,()=>{
            let keywords = document.head.querySelector('meta[name="keywords"]'),
                url = document.head.querySelector('meta[name="canonical"]'),
                description = document.head.querySelector('meta[name="description"]'),
                language = lang;

            if(["cs","de", "da", "en", "es", "fr", "ja", "ko", "nl", "pt", "ru", "sk", "tr", "zh"].indexOf(lang) === -1) {
                language = "en";
            }

            navigator.share({
                    title: document.title,
                    text: (description !== null) ? description.getAttribute("content") : "",
                    url: (url !== null) ? url.getAttribute("content") : location.href,

                    // fbId: '123456789123456',
                    hashtags: (keywords !== null) ? keywords.getAttribute("content") : ""
                },
                {
                    copy: true,
                    email: true,
                    print: false,
                    sms: false,
                    messenger: true,
                    facebook: true,
                    whatsapp: true,
                    twitter: true,
                    linkedin: true,
                    telegram: false,
                    skype: false,
                    language: language
                }
            )
                .then()
                .catch( error => console.log(error));
        });
    }
});