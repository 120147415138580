if (typeof Swup !== "undefined") {
    swup = new Swup({
        containers: ['#layout_main', '#layout_header', '#layout_nav', '#layout_footer'],
        linkSelector: `a[href^="${window.location.origin}"]:not([data-no-swup]):not([target="_blank"]), a[href^="/"]:not([data-no-swup]):not([target="_blank"]), a[href^="#"]:not([data-no-swup])`
    });

    swup.on('clickLink', () => {
        document.body.classList.remove("overflow--hidden");

        if (document.querySelector(".drawer-active") !== null) {
            application.getController(document.body, "lib-drawer").hide()
        }

        if (document.querySelector(".lib--dialog") !== null) {
            LibDialog.close();
        }
    });

    document.addEventListener('swup:animationOutDone', () => {
        window.scrollTo(0, 0);

        if (typeof window.swupActive === "undefined") {
            window.swupActive = true;
        }
    });

    swup.on('contentReplaced', function () {
        document.documentElement.classList.remove("is--header-not-top", "is--header-pinned", "is--header-unpinned");

        const content = new DOMParser().parseFromString(swup.cache["getCurrentPage"]().originalContent, "text/html");

        [...content.head.querySelectorAll("[data-lib-swup-replace]")].forEach(element => {
            let tag = document.querySelector(`[data-lib-swup-replace=${element.dataset.libSwupReplace}]`);

            if (tag !== null) {
                tag.outerHTML = element.outerHTML
            } else {
                document.head.insertAdjacentHTML("beforeend", element.outerHTML);
            }
        });

        [...document.querySelectorAll(".ss-content")].forEach(element => {
            element.remove();
        });

        [...document.querySelectorAll(".datepicker")].forEach(element => {
            element.remove();
        });

        if (typeof locomotive !== "undefined") {
            locomotive.destroy();
            document.getElementById("layout_scroll").removeAttribute("style");
            if (document.querySelector("[data-no-locomotive]") === null) {
                locomotive.init();

                locomotive.on("scroll", (e) => {
                    let position = e.scroll.y;

                    if (position > 40) {
                        document.documentElement.classList.add("is--header-not-top");
                    } else {
                        document.documentElement.classList.remove("is--header-not-top", "is--header-pinned", "is--header-unpinned");
                    }
                })
            }
        }

        swup.options.containers.forEach((selector) => {
            loadStimulus(document.querySelector(selector));
        });

        application.getController(document.body, "lib-drawer").init();

        LibAnchor.init();



        if (typeof fbq !== "undefined") {
            fbq('track', 'PageView');
        }

        if (typeof gtag !== "undefined") {
            let configs = [];
            window.dataLayer.forEach(function (config) {
                if (config[0] === "config") {
                    if (typeof config[1] !== "undefined" && !configs.includes(config[1])) {
                        configs.push(config[1]);
                        gtag('config', config[1], {
                            'page_title': document.title,
                            'page_path': window.location.pathname + window.location.search
                        });
                    }
                }
            });
        }

        if (typeof window.dataLayer !== "undefined") {
            window.dataLayer.push({
                'event': 'VirtualPageview',
                'virtualPageURL': window.location.pathname + window.location.search,
                'virtualPageTitle': document.title
            });
        }

        if (typeof window.seznam_retargeting_id !== "undefined") {
            importScript("https://c.imedia.cz/js/retargeting.js");
        }

        if (typeof LibCookieConsent !== "undefined") {
            LibCookieConsent.init();
        }

        document.body.classList.remove("body--preload");
        document.body.classList.add("body--loaded");
    });
}