application.register("part_cookieconsent", class extends Stimulus.Controller {
    connect() {
        const selector = this.element;

        if (localStorage.getItem('cookieconsent') === null) {
            LibCookieConsent.append("all");
            setTimeout(() => {
                cssLoaded(function(){
                    selector.classList.add("state--active", "is--animate");
                });
            },1500);
        }

        LibCookieConsent.init();

        selector.addEventListener("click", () => {
            selector.classList.add("is--mobile-show");
        });

        selector.querySelector("[data-lib-cookieconsent-approve]").addEventListener("click", () => {
            LibCookieConsent.set("approve");
            selector.classList.remove("is--animate");

            setTimeout(function(){
                selector.classList.remove("state--active");
                selector.remove();
            },500);
        });

        selector.querySelector("[data-lib-cookieconsent-decline]").addEventListener("click", () => {
            LibCookieConsent.set("performance");
            selector.classList.remove("is--animate");

            setTimeout(function(){
                selector.classList.remove("state--active");
                selector.remove();
            },500);
        });
    }
});

application.register("part_form_cookieconsent", class extends Stimulus.Controller {
    connect() {
        const selector = this.element;

        if (localStorage.getItem('cookieconsent') !== null) {
            if (localStorage.getItem('cookieconsent') === "approve") {
                let type = localStorage.getItem('cookieconsent_type');
                if (type !== null) {
                    if (type === "performance") {
                        selector.querySelector(`input[value="performance"]`).checked = true;
                    } else {
                        selector.querySelector(`input[value="approve"]`).checked = true;
                    }
                } else {
                    selector.querySelector(`input[value="approve"]`).checked = true;
                }
            } else if (localStorage.getItem('cookieconsent') === "decline") {
                selector.querySelector(`input[value="decline"]`).checked = true;
            }
        }

        selector.addEventListener("submit", (e) => {
            e.preventDefault();

            let value = (new FormData(e.target)).get("cookies");

            if (value === "approve") {
                LibCookieConsent.set("marketing");
                location.reload();
            } else if (value === "performance") {
                LibCookieConsent.set("performance");
                location.reload();
            } else if (value === "decline") {
                LibCookieConsent.set("decline", () => location.reload());
            }
        })
    }
});