let dynamicControllers = ["part_ui_input", "part_ui_select", "part_ui_wsw", "part_cookieconsent", "part_form_cookieconsent", "part_ui_range"];
let dynamicActions = [[`a[href^="#"]`, "click->lib#anchor"], [`.part_ui_btn, .part_ui_link`, "click->lib#ripple"]];
let lang = document.documentElement.lang;
let swup;
let locomotive;
let lastScrollTop = 0;

const application = Stimulus.Application.start(document.documentElement);

Stimulus.Controller.prototype.getValue = function(value) {
    return this[`${value}Value`]
};

Stimulus.Controller.prototype.setValue = function(type, value) {
    this[`${type}Value`] = value;
};

Stimulus.Controller.prototype.hasValue = function(type, value) {
    return this.data.has(`${type}Value`, value);
};

Stimulus.Controller.prototype.getClass = function(value) {
    return this.data.get(`${value}Class`)
};

Stimulus.Controller.prototype.getTarget = function(target) {
    return this.targets.find(target)
};

Stimulus.Controller.prototype.getTargetAll = function(targets) {
    return this.targets.findAll(targets)
};

Stimulus.Controller.prototype.hasTarget = function(target) {
    return this.targets.has(target)
};

application.getController = (element, identifier) => application.getControllerForElementAndIdentifier(element, identifier);

importScript.used = [];
importScript.loaded = [];

if ('serviceWorker' in navigator && location.protocol === "https:") {
    window.addEventListener('load', function () {
        if (!document.documentElement.classList.contains("no-sw")) {
            navigator.serviceWorker.register('/sw.js').catch(function (e) {
                console.error('Error during service worker registration:', e);
            });
        } else {
            navigator.serviceWorker.getRegistrations().then(function (registrations) {
                if (registrations.length > 0) {
                    for (let registration of registrations) {
                        registration.unregister()
                    }
                }
            });
        }
    });
}

document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);

document.addEventListener("resize",()=>{
    document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
});

(function locomotiveScroll() {
    bodyLoaded(function () {
        if (!document.documentElement.classList.contains("ie")) {
            locomotive = new LocomotiveScroll({
                el: document.getElementById('layout_scroll'),
                smooth: true,
                getSpeed: true,
                getDirection: true,
                lerp: 0.1
            });

            setTimeout(function () {
                if (document.querySelector("[data-no-locomotive]") !== null) {
                    locomotive.destroy();
                    document.getElementById("layout_scroll").removeAttribute("style");
                    return;
                }
                locomotive.update();
                setTimeout(() => window.dispatchEvent(new Event('resize')), 10);

                const resizeObserver = new ResizeObserver(() => {
                    locomotive.update()
                });

                resizeObserver.observe(document.body);

            }, 1000);

            locomotive.on("scroll", (e) => {
                let position = e.scroll.y;

                if (position > 40) {
                    document.documentElement.classList.add("is--header-not-top");
                } else {
                    document.documentElement.classList.remove("is--header-not-top", "is--header-pinned", "is--header-unpinned");
                }
            })
        }
    });
})();

if (document.body.classList.contains("body--preload")) {
    cssLoaded(function () {
        document.body.classList.remove("body--preload");
        setTimeout(function () {
            document.body.classList.add("body--loaded");
        }, 300);
    });
}

if (typeof WebFontConfig === "undefined" && document.querySelector("[data-loadcss]") !== null) {
    if (typeof document.fonts !== "undefined") {
        document.fonts.ready.then(function () {
            document.documentElement.classList.add("wf-active");
        });

        setTimeout(() => {
            if (!document.documentElement.classList.contains("wf-active")) {
                document.documentElement.classList.add("wf-active");
            }
        }, 500);
    } else {
        document.documentElement.classList.add("wf-active");
    }
}

if (document.createElement('picture').toString().indexOf("HTMLPictureElement") === -1 && !document.documentElement.classList.contains("ie")) {
    importScript(cdnjs.picturefill);
}

if (typeof document.documentElement.style.grid !== 'string') {
    document.documentElement.classList.add("no-grid");
}

window.addEventListener('scroll', (e) => {
    let position = document.documentElement.scrollTop;

    if (position > 40) {
        document.documentElement.classList.add("is--header-not-top");
    } else {
        document.documentElement.classList.remove("is--header-not-top", "is--header-pinned", "is--header-unpinned");
    }

    if (position > lastScrollTop){
        document.documentElement.classList.remove("is--header-pinned");
        document.documentElement.classList.add("is--header-unpinned");
    } else {
        document.documentElement.classList.remove("is--header-unpinned");
        document.documentElement.classList.add("is--header-pinned");
    }

    lastScrollTop = position <= 0 ? 0 : position;
});

if(location.hash === "#podlespecifikace") {
    easterEgg();
}

window.addEventListener("hashchange",() => {
    if(location.hash === "#podlespecifikace") {
        easterEgg();
    }
});

function easterEgg() {
    let interval;
    if(document.querySelector("#easteregg") !== null) {
        document.querySelector("#easteregg").remove();
        clearInterval(interval);
    }

    document.body.insertAdjacentHTML("afterend",`<div id="easteregg"></div>`);
    let egg = document.querySelector("#easteregg");

    interval = setInterval(() => {
        let randPosition = Math.round(Math.random() * (window.innerWidth - 300)+300);

        if(randPosition > window.innerWidth / 2) {
            egg.classList.add("is--reverse");
        }
        else {
            egg.classList.remove("is--reverse");
        }

        egg.style.left = randPosition + "px";
    }, 6000);
}