application.register("comp_timeline_development", class extends Stimulus.Controller {
    get target() {
        return {
            body: this.targets.find("body")
        }
    }

    scrollToNext() {
        LibAnchor.animation(this.target.body, 125);
    }
});