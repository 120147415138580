loadStimulus(document);

application.register("lib", class extends Stimulus.Controller {
    ripple(e) {
        LibRipple(e.currentTarget, e)
    }

    anchor(e) {
        e.preventDefault();
        LibAnchor.action(e.currentTarget)
    }

    print(e) {
        e.preventDefault();
        window.print();
        /*
        importScript(cdnjs.printjs,()=>{
            printJS({ printable: 'layout', type: 'html', targetStyles: ['*'] });
        });
        */
    }

    favoritesAdd(e) {
        document.body.insertAdjacentHTML("beforeend", '<div class="layout_summary_circle"></div>');
        let circles = document.querySelectorAll(".layout_summary_circle")
        let summary_circle =  circles[circles.length - 1];
        let basket;
        let left;

        if (window.innerWidth > 960) {
            basket = document.querySelector("#layout_header .icon--menu-favorites").closest(".elm_action_item");
            left = basket.offsetLeft + basket.offsetWidth + "px";
        } else {
            basket = document.querySelector("#layout_header .elm_header_menu");
            left = basket.offsetLeft + 24 + "px";
        }

        summary_circle.style.top = e.clientY + "px";
        summary_circle.style.left = e.clientX + "px";

        setTimeout(()=>{
            summary_circle.style.top = basket.offsetTop + 24 + "px";
            summary_circle.style.left = left;
            summary_circle.style.width = "1rem";
            summary_circle.style.height = "1rem";
            summary_circle.style.opacity = "0.1"

            setTimeout(()=>{
                summary_circle.parentElement.removeChild(summary_circle);
            },800)
        },10);
    }
});

application.register("lib-dialog", class extends Stimulus.Controller {
    connect() {
        let element = this.element;

        if (typeof element.dataset.libDialogOpen !== "undefined") {
            let url = element.getAttribute("data-action-url");

            if (url) {
                LibDialog.action(element, url, () => loadStimulus(document.querySelector(".lib--dialog")) );
            } else {
                LibDialog.show(document.querySelector(element.dataset.libDialogOpen).innerHTML, () => loadStimulus(document.querySelector(".lib--dialog")));
            }
        }
    }

    show(e) {
        e.preventDefault();
        e.stopPropagation();

        let element = e.currentTarget;
        let url = element.getAttribute("data-action-url");

        LibDialog.action(element, url, () => loadStimulus(document.querySelector(".lib--dialog")));
    }

    hide() {
        LibDialog.hide();

    }
});

application.register("lib-tabs", class extends Stimulus.Controller {
    connect() {
        LibTabs(this.element);
    }
});

application.register("lib-ns", class extends Stimulus.Controller {
    connect() {
        if (!('scrollBehavior' in document.documentElement.style)) {
            importScript(cdnjs.seamless, () => seamless.polyfill());
        }

        bodyLoaded(() => LibNativeSlider(this.element.querySelector("[data-lib-ns]"), this.element))
    }
});

application.register("filter", class extends Stimulus.Controller {
    setPage(e) {
        e.preventDefault();
        this.element.querySelector('form [name=page]').value = e.currentTarget.dataset.page;
        this.element.querySelector('form').dispatchEvent(new Event('submit', {'bubbles': true, 'cancelable': true}));
        LibAnchor.animation(this.element,150);
    }
});