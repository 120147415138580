window.lui_map_responses = {};

application.register("part_map", class extends Stimulus.Controller {

    get target() {
        return {
            map: this.targets.find("map"),
            infoWindow: this.targets.find("infoWindow"),
            infoWindowBasic: this.targets.find("infoWindowBasic"),
        }
    }

    connect() {
        let self = this, element = self.element, options = JSON.parse(element.dataset.options),
            markersUrl = element.dataset.markers,
            markerMain = element.dataset.markerMain;

        if(typeof markerMain !=="undefined") {
            markerMain = JSON.parse(markerMain);
        }

        inView(element, () => {
            importScript([cdnjs.googlemaps.replace("{apikey}", options["apikey"]), cdnjs.googlemaps_cluster], () => {
                let coords = options["center"].split(/,\s/);
                let bounds;
                let markers_temp = [];
                let info_template = self.target.infoWindow.innerHTML;
                let infoBasic_template = self.target.infoWindowBasic.innerHTML;

                if(typeof markerMain !== "undefined") {
                    coords = [markerMain["latitude"], markerMain["longitude"]];
                }

                let map = new google.maps.Map(self.target.map, {
                    center: {lat: parseFloat(coords[0]), lng: parseFloat(coords[1])},
                    zoom: 13,
                    zoomControl: true,
                    mapTypeControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: false,
                    styles: [
                        {
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#f5f5f5"
                                }
                            ]
                        },
                        {
                            "elementType": "labels.icon",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#616161"
                                }
                            ]
                        },
                        {
                            "elementType": "labels.text.stroke",
                            "stylers": [
                                {
                                    "color": "#f5f5f5"
                                }
                            ]
                        },
                        {
                            "featureType": "administrative.land_parcel",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#bdbdbd"
                                }
                            ]
                        },
                        {
                            "featureType": "poi",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#eeeeee"
                                }
                            ]
                        },
                        {
                            "featureType": "poi",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#757575"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.park",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#e5e5e5"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.park",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#9e9e9e"
                                }
                            ]
                        },
                        {
                            "featureType": "road",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#ffffff"
                                }
                            ]
                        },
                        {
                            "featureType": "road.arterial",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#757575"
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#dadada"
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#616161"
                                }
                            ]
                        },
                        {
                            "featureType": "road.local",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#9e9e9e"
                                }
                            ]
                        },
                        {
                            "featureType": "transit.line",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#e5e5e5"
                                }
                            ]
                        },
                        {
                            "featureType": "transit.station",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#eeeeee"
                                }
                            ]
                        },
                        {
                            "featureType": "water",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#c9c9c9"
                                }
                            ]
                        },
                        {
                            "featureType": "water",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#9e9e9e"
                                }
                            ]
                        }
                    ]
                });

                let infoWindow = new google.maps.InfoWindow({
                    content: "",
                    minWidth: 300
                });

                bounds = new google.maps.LatLngBounds();

                function getMarkers(url, callback) {
                    if (typeof window.lui_map_responses[url] === "undefined") {
                        fetch(url, {headers: {'X-Requested-With': 'XMLHttpRequest'}}).then((response) => {
                            return response.json();
                        }).then((payload) => {
                            window.lui_map_responses[url] = payload;
                            callback(payload);
                        });
                    } else {
                        callback(window.lui_map_responses[url]);
                    }
                }

                function generateMarker(item) {
                    let icon, iconActive;
                    let title = item["title"];
                    let point = new google.maps.LatLng(parseFloat(item["latitude"]), parseFloat(item["longitude"]));
                    let content;

                    icon = {
                        url: item["icon"],
                        scaledSize: new google.maps.Size(46, 54),
                        anchor: new google.maps.Point(23, 54)
                    };

                    if(typeof item["iconActive"] !== "undefined") {
                        iconActive = {
                            url: item["iconActive"],
                            scaledSize: new google.maps.Size(78, 94),
                            anchor: new google.maps.Point(39, 94)
                        };
                    }
                    else {
                        iconActive = icon;
                    }

                    let marker = new google.maps.Marker({
                        title: title,
                        map: map,
                        position: point,
                        icon: icon,
                        data: {
                            icon: icon,
                            iconActive: iconActive
                        }
                    });
                    bounds.extend(point);
                    markers_temp.push(marker);

                    if(item["hasIw"]) {
                        content = self.target.infoWindow.innerHTML;

                        Object.entries(item).forEach((key) => {
                            if(key[1] === "" || key[1] === 0 || key[1] === null) {
                                content = content.replace(`elm_info_${key[0]}`, `elm_info_${key[0]} display--none`);
                            }
                            else {
                                content = content.replace(`*${key[0]}*`, key[1]);
                            }
                        });
                    }
                    else {
                        content = self.target.infoWindowBasic.innerHTML;

                        Object.entries(item).forEach((key) => {
                            content = content.replace(`*${key[0]}*`, key[1]);
                        });
                    }

                    function showInfo(marker) {
                        for (let marker_temp of markers_temp) {
                            marker_temp.setIcon(marker_temp.data["icon"]);
                            marker_temp.setZIndex(0);
                        }
                        marker.setIcon(marker.data["iconActive"]);
                        marker.setZIndex(100);

                        infoWindow.close();
                        infoWindow.setContent(content);
                        infoWindow.open(map, marker);
                    }

                    marker.addListener('click', () => {
                        showInfo(marker);
                    });

                    marker.addListener('mouseover', () => {
                        showInfo(marker);
                    });

                    map.addListener("click", () => {
                        infoWindow.close();
                        for (let marker_temp of markers_temp) {
                            marker_temp.setIcon(marker_temp.data["icon"]);
                            marker_temp.setZIndex(0);
                        }
                    })
                }

                if(typeof markersUrl !== "undefined") {
                    getMarkers(markersUrl, (payload) => {
                        for (let item of payload) {
                            generateMarker(item);
                        }
                        let markerCluster = new MarkerClusterer(map, markers_temp, {
                            styles: [{
                                url: "/assets/img/marker-cluster.svg",
                                width: 36,
                                height: 36,
                                fontFamily: "Jost,sans-serif",
                                fontWeight: "600",
                                textSize: 16,
                                textColor: "#FFFFFF",
                                textLineHeight: 36
                            }]
                        });

                        map.addListener("zoom_changed", () => {
                            if(map.getZoom() > 14) {
                                markerCluster.setMap(null);
                            }
                            else {
                                markerCluster.setMap(map);
                            }
                        });

                        if (payload.length > 0) {
                            map.fitBounds(bounds);

                            if(payload.length === 1) {
                                map.setZoom(13)
                            }
                        }
                    });
                }

                if(typeof markerMain !== "undefined") {
                    let point = new google.maps.LatLng(parseFloat(markerMain["latitude"]), parseFloat(markerMain["longitude"]));
                    let marker = new google.maps.Marker({
                        title: markerMain["title"],
                        map: map,
                        position: point,
                        icon: {
                            url: "/assets/img/marker.png",
                            scaledSize: new google.maps.Size(78, 94),
                            anchor: new google.maps.Point(39, 94)
                        },
                        url: markerMain["url"]
                    });
                    bounds.extend(point);

                    marker.addListener('click', () => {
                        window.open(marker.url);
                    });
                }
            });
        })
    }
});