application.register("part_ui_range", class extends Stimulus.Controller {
    connect() {
        let elm = this.element;

        importScript(cdnjs.noui, () => {
            LibNoUI(elm, {
                elm_min: elm.querySelector(`[data-range-input="min"]`),
                elm_max: elm.querySelector(`[data-range-input="max"]`),
                elm_min_hidden: elm.querySelector(`[data-range-input="min"]`).nextElementSibling,
                elm_max_hidden: elm.querySelector(`[data-range-input="max"]`).nextElementSibling,
                unit: elm.dataset.unit
            });
        });
    }
});